.form-group ,.input-group{

    &.form-group-md-input {
        label {
            color: #6c757d;            
            font-size: 80%;
            font-weight: 400;
        }
        .form-control {
            background: transparent;
            border-bottom-width: 1px;
            border-top: 0;
            border-left: 0;
            border-right: 0;
            padding-left: 0;
            padding-right: 0;
        }
    }

    &.form-group-md {
        label {
            // color: #539bd4;
            font-weight: 500;
        }
        .form-control {
            background: transparent;
            border-bottom-width: 2px;
            border-top: 0;
            border-left: 0;
            border-right: 0;
            // color: $white;
            padding-left: 0;
            padding-right: 0;
            border-color: $dark;
            &::-webkit-input-placeholder {
                // color: $white;
                // opacity: 0.5;
            }
            &::-moz-placeholder {
                // color: $white;
                // opacity: 0.5;
            }
            &:-ms-input-placeholder {
                // color: $white;
                // opacity: 0.5;
            }
            &::-ms-input-placeholder {
                // color: $white;
                // opacity: 0.5;
            }
            &::placeholder {
                // color: $white;
                // opacity: 0.5;
            }
        }
    }
    &.form-group-md-select {
        position: relative;
        width: 70px;
        &:after {
            content: "\f0d7";
            position: absolute;
            right: 0;
            bottom: 0.35rem;
            font-family: "Font Awesome 5 Free";
            font-weight: 900;
        }
        select {
            &.form-control {
                background: transparent;
                border-bottom-width: 1px;
                border-top: 0;
                border-left: 0;
                border-right: 0;
                padding-left: 0;
                padding-right: 0;
                -webkit-appearance: none;
                -moz-appearance: none;
                appearance: none;
                text-indent: 0.01px;
                text-overflow: "";
                &::-webkit-input-placeholder {
                    color: $white;
                    // opacity: 0.5;
                }
                &::-moz-placeholder {
                    color: $white;
                    // opacity: 0.5;
                }
                &:-ms-input-placeholder {
                    color: $white;
                    // opacity: 0.5;
                }
                &::-ms-input-placeholder {
                    color: $white;
                    // opacity: 0.5;
                }
                &::placeholder {
                    color: $white;
                    // opacity: 0.5;
                }
            }
        }
    }
}
