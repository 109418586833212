// This file contains all styles related to the header.

header{
    .navbar-inner {
        padding: .625rem 1.875rem; //10px 30px

        .header-pipeline{
            padding-left: .75rem;
        }

        // @include mq("desktop", max) {
        //     padding: 1.25rem 1.875rem; //20px 30px
        // }

        // @include mq("phablet", max) {
        //     .logo {
        //         height: 31px;
        //     }
        // }

        // @include mq("phone-wide", max) {
        //     padding: 1rem;
        // }
    }
}

.header-title{
    vertical-align: middle;
    font-size: 1.2rem;
}

/*dropdown css START*/
.uw-dropdown{
    .dropdown-menu{
        padding: 0px 1.25rem;
        box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.43);
        border: 0;
        .dropdown-item{
            color: $primary;
            font-size: 0.875rem;
            border-bottom: 1px solid #c6e0f2;
            padding: 0.5rem 0;
            &:hover, &:active{
                background-color: transparent;
            }
            &:focus{
                outline: none;
            }
            &:last-child{
                border-bottom: 0;
            }
            span{
                width: 25px;
                display: inline-block;
            }
        }
    }
}

.assistoc-program{
    header {
        .navbar-inner{
            padding: 1.625rem 3.625rem;
        }
    }
}

.assist-oc-logo{
    border-right: 1px solid #8f8f8f;
    padding-right: 15px;
}