.welcomeoc-program .profile-title {
    font-size: 1.2rem;
    color: $red;
    text-transform: uppercase;
    font-weight: bold;
}

.welcomeoc-program{
    .profile-ylbox{
        background: #ded028;
        padding: .9375rem;
        color: #050505;
    
        label{
            color: #6d6d6d;
            font-size: .875rem;
            font-weight: 500;
        }
    
        .form-control-static{
            font-size: 1rem;
            font-weight: 600;
        }
    
        .edit-profile{
           float: right;
           cursor: pointer;
            i{
                width: 2.1875rem;
                height: 2.1875rem;
                background: #000000;
                border-radius: 100%;
                color: #fff;
                font-size: 1.25rem;
                padding: 7px;
            }
        }
    }
}

.welcomeoc-program{
    .profile-status{
        color: #020202;
        font-size: .75rem;
        font-weight: bold;
    
        span{
            background: #47ba01;
            color: $white;
            border-radius: 8px;
            border-radius: 25px;
            padding: 5px 8px;
            margin-left: 5px;
            font-weight: 400;
        }
    }
}

.welcomeoc-program{
    .round-img{
        width: 1.875rem;
        height: 1.875rem;
        background: #5c9ad0;
        display: inline-block;
        border-radius: 100%;
        text-align: center;
        line-height: 30px;
        vertical-align: middle;
        color: $white !important;
        &.green{
            background: #70c4aa;
            padding-left: .25rem;
            img{
                margin-left: .3125rem;
            }
        }
        img{
            width: 1.25rem;
        }
    }
}

.welcomeoc-program{
    .program-name{
        font-size: .875rem;
        font-weight: 500;
        text-decoration: underline;
        text-transform: uppercase;
        color: $black;
    }
}

.welcomeoc-program{
    .profile-title-main{
        padding-bottom: .625rem;
        .profile-title{
            display: inline-block;
            vertical-align: middle;
            margin-bottom: 0;
        }
    
        button{
            display: inline-block;
            vertical-align: middle;
            font-size: .875rem;
            padding: .3125rem 1.25rem;
        }
    }
}

.welcomeoc-program{
    .property-box{
        background: $white;
        border-bottom: 4px solid #70c4aa;
        box-shadow: 0px 0px 7px 0px rgba(2, 2, 2, 0.11);
        padding: .9375rem;
        transition: all 0.5s ease-out;
        height: 100%;
    
        &.border-blue{
            border-bottom: 4px solid #5b9dd5;
        }
    
        &:hover{
            box-shadow: 0px 0px 13px 0px rgba(2, 2, 2, 0.7);
        }
    
        label{
            font-size: .75rem;
            color: #6d6d6d;
            margin-bottom: 0;
        }
    
        .form-control-static{
            font-size: .875rem;
            font-weight: 600;
            line-height: initial;
            margin-bottom: 0;
        }
    
        .property-img{
            width: 8rem;
            height: 8rem;
            border-radius: 100%;
        }
    
        .property-content{
            display: inline-block;
            vertical-align: top;
            margin-left: 1rem;
            width: 62%;
        }
    }
}

.welcomeoc-program{
    .btn-red{
        background: #e13e3b;
        padding: .3125rem .625rem;
        border-color: #e13e3b;
        margin-top: 2.1875rem;
        margin-bottom: 1.5625rem;
    }
}

// modal popup css START
.welcomeoc-program{
    .modal-header{
        background-color:$blue;
        border: 0;
    }
}
.welcomeoc-program{
    .modal-title{
        color: #fff;
        text-transform: uppercase;
    }
}
.welcomeoc-program{
    .modal-content{
        border: 0;
    }
}
.welcomeoc-program{
    .close{
        color: #fff;
        opacity: 1;
        font-size: 18px;
        line-height: initial;
    }
}
.welcomeoc-program{
    button:focus{
        outline: 0;
    }
}
.welcomeoc-program{
    .modal-header .close{
        margin: 0;
        padding: 0;
    }
}
.welcomeoc-program{
    .modal-body{
        label{
            font-size: .75rem;
            color: #8e8e8e;
        }
        .form-control{
            font-size: .875rem;
            font-weight: 500;
            color: #020202;
        }
    }
}
.welcomeoc-program{
    .modal-footer{
        border-top: 0;
        padding-top: 0;
    }
}

.col-md-2{
    &.whoc-col-2{
        @include mq("tablet-mini", min) {
            flex: 0 0 22.22%;
            max-width: 22.22%;
        }
    }
}

.welcomeoc-program{
    .property-search-box{
        position: absolute;
        top: 56px;
        right: 0;
        z-index: 2;
        width:36.25rem;
        .multi-collapse{
            box-shadow: 0px 0px 8.46px 0.54px rgba(2, 2, 2, 0.71);
            .filter-close{
                font-size: 1.25rem;
                text-align: right;
            }
        }
    }
}

.welcomeoc-program{
    .green{
        color: #47ba01;
      }
    .red{
        color: #d30000;
        &:hover{
            color: #bf0303;
        }
    }
    .blue{
        color: #4679c5;
    }
    .pending-bg{
        background: #d4c403 !important;
    }
    .inactive-bg{
        background: #646363 !important;
    }
    .leased-bg{
        background: #4679c5 !important;
    }
    .under-rent-review-bg{
        background: #ac6ad8 !important;
    }
    .rent-discussion-bg{
    background: #f5b403 !important;
    }
    .holding-fee-agreement-needed-bg{
    background: #fa03e5 !important;
    }
    .active-bg{
    background: #51fa02 !important;
    }
    .matched-bg{
    background: #fae902 !important;
    }
    .not-ready-bg{
    background: #01e9fa !important;
    }
    .bg-dark{
        background-color: $black;
    }
}

/*search area css START*/
.welcomeoc-program{
    .search-area{
        label{
            font-size: .8125rem;
            margin-bottom: 0;
        }
        .form-control{
            height: 1.875rem;
            font-size: .8125rem;
        }
        button{
            font-size: .8125rem;
            height: 30px;
            line-height: initial;
        }
        .col-sm-2{
            @include respond-between(phablet, desktop) {
                flex: 0 0 20%;
                max-width: 20%;
            }
        }
    }
}
/*search area css END*/

/*property detial page css START*/
.welcomeoc-program{
    .property-detail-main{
        .box-gray{
            background: #f1f0f6 !important;
            border-bottom: 4px solid #e1d547 !important;
        }
        .profile-title{
            font-size: 1.2rem;
            background: #fff !important;
            padding-bottom: .3125rem;
        }
        .box-details{
            padding:0rem .9375rem;
            label{
                font-size: .8125rem;
                margin-bottom: 0;
                position: relative;
            }
            .form-control{
                height: 1.875rem;
                font-size: .8125rem;
            }
        }
        .img-box{
            justify-content: center;
            align-items: center;
            display: flex;
            position: relative;
            transition: all 0.5s ease-out;
            &:hover{
                .custom-file-upload{
                    display: block;
                }
            }
        }
    }
}
/*property detial page css END*/

/*custom choose file css START*/
.welcomeoc-program{
    .custom-file-upload{
        display: none;
        width: 100%;
        height: 100%;
        background: rgba(0,0,0,0.2);
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        transition: all 0.5s ease-out;
        .custom-upload-inner{
            width: 100%;
            transform: translateY(-50%);
            position: absolute;
            top: 50%;
            .btn-primary{
                background-color: #70c4aa;
                border-color: #70c4aa;
            }
            input{
                display: none;
            }
        }
    }
    
    .doc-name{
        font-size: 13px;
        padding-top: 10px;
        font-weight: bold;
    }
    
    .list-group {
        display: block;
        flex-direction: row;
        padding-left: 0;
        margin-bottom: 0;
    }
    .list-group-item {
        position: relative;
        display: block;
        padding: 5px 1.25rem;
        background-color: transparent;
        border: 1px solid rgba(2, 2, 2, 0.125);
        font-size: 14px;
        width: 32.33%;
        margin: 0 10px 0 0;
        i{
            color: $blue;
        }
    }
    .list-group-flush > .list-group-item:last-child {
        border-bottom-width: 1px;
    }
    
    .property-grid{
        table{
            &.table-scroll{
                thead {
                     width: calc( 100% - 1em );
                }
            }
            thead{
                display: table;
                width: 100%;
                table-layout: fixed;
            }
            thead {
                th{
                    font-weight: 400;
                }
            }
            tbody{
                display: block;
                max-height: 180px;
                overflow: auto;
                @include mq("tablet-small", max) {
                   overflow-y: auto;
                   overflow-x: hidden;
                }
                tr{
                    display: table;
                    width: 100%;
                    table-layout: fixed;
                }
            }
            th, td{
                 border-top: 0;
                 @include mq("tablet-small", max) {
                    width:150px
                }
                &:last-child{
                    @include mq("tablet-wide", max) {
                        width:50px;
                    }
                }
            }
            th{
                border-top:0;
                font-size: 13px;
                padding-bottom: 5px;
                border-bottom: 1px solid #dee2e6;
            }
            td{
                margin-bottom: .3125rem;
                border-bottom: 1px solid #dee2e6;
                border-left: 0;
                border-right: 0;
            }
        }
        .form-control{
            height: 1.875rem;
            font-size: .8125rem;
        }
        label{
            font-size: .8125rem;
            font-weight: 500;
            margin-bottom: .3125rem;
        }
        .row-bg{
            background: #fff;
            padding: .5rem 0;
            border: 1px solid #c5c4c4;
            margin-bottom: .3125rem;
        }
        .col-1{
            align-self: center;
        }
        i{
            font-size: 1.125rem;
            color: $red;
            cursor: pointer;
        }
    }
    
    .property-grid-add{
        float: right;
        i{
            color: $red;
            cursor: pointer;
        }
    }
}

.welcomeoc-program{
    .welcome-grid{
        &.search-claims-form{
            .MuiToolbar-root{
                background: $c-green;
                border-bottom: #000;
            }
            .MuiTablePagination-toolbar{
                background: $white;
            }
            .MuiSvgIcon-root{
                color: $black;
            }
            .MuiIconButton-root:hover {
                background-color:#328269;
                color: #fff;
                .MuiSvgIcon-root {
                    color: #fff;
                }
            }
            i{
                color: #50ad90 !important;
            }
            thead.MuiTableHead-root {
                tr {
                    th{
                        background-color: #d8fbf0;
                    }
                }
            }
        }
        .MuiButton-textPrimary {
            color: $red;
        }
        .MuiFormLabel-root.Mui-focused {
            color:$red;
        }
        .MuiInput-underline:after{
            border-bottom: 2px solid $red;
        }
    }
    
    .btn-black{
        &:hover{
            background-color: #000;
            border-color: #000;
        }
    }
}

// modal popup css START
.welcomeoc-program{
    .modal-header{
        background-color:$blue;
        border: 0;
    }
    .modal-title{
        color: #fff;
        text-transform: uppercase;
    }
    .modal-content{
        border: 0;
    }
    .close{
        color: #fff;
        opacity: 1;
        font-size: 18px;
        line-height: initial;
    }
    button:focus{
        outline: 0;
    }
    .modal-header .close{
        margin: 0;
        padding: 0;
    }
    .modal-body{
        label{
            font-size: .75rem;
            color: #8e8e8e;
        }
        .form-control{
            font-size: .875rem;
            font-weight: 400;
            color: #020202;
        }
        .col-form-label {
            font-size: 0.875rem;
            color: #8e8e8e;
        }
    }
    .modal-footer{
        border-top: 0;
        padding-top: 0;
    }
}
// modal popup css END

.welcomeoc-program{
    .btn-black{
        background-color: $black;
        border-color: $black;
    }
    .alert-small-text{
        color: $red;
        font-size: .75rem;
        background-color: transparent;
        border: 0;
        padding: 0;
        font-weight: 500;
        position: absolute;
    }
}

/*radio css START*/
.welcomeoc-program{
    .whoc-radio{
        .custom-control-input:checked ~ .custom-control-label::before {
            color: #fff;
            border-color: $blue;
            background-color: $blue;
        }
        .custom-control-input:not(:disabled):active ~ .custom-control-label::before {
            color: #fff;
            background-color: $blue;
            border-color: $blue;
        }
        .custom-control-input:focus ~ .custom-control-label::before {
            box-shadow: none;
        }
        .custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
            border-color: #8fc2ef;
        }
        
        .custom-control-input:not(:disabled):active ~ .custom-control-label::before {
            color: #fff;
            background-color: #a9d6fd;
            border-color: #a9d6fd;
        }
    }
    
    .btn{
        &.whoc-btn{
            text-transform: uppercase;
            font-weight: 500;
            font-size: .875rem;
            padding: .1875rem .5rem;
            &:focus{
                background-color: $black;
                border-color: $black;
                box-shadow: none;
            }
        }
    }
    
    .btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus, .show > .btn-primary.dropdown-toggle:focus {
        box-shadow: none;
    }
    
    .whoc-float-btn{
        width: 50px;
        height: 50px;
        border-radius: 100%;
        position: fixed;
        right: 24px;
        bottom: 44px;
        background-color: $red;
        border-color: $red;
        line-height: initial;
        padding: .3125rem;
        box-shadow: 0px 3px 5px -1px rgba(0,0,0,0.2), 0px 6px 10px 0px rgba(0,0,0,0.14), 0px 1px 18px 0px rgba(0,0,0,0.12);
    
        &:hover{
            background-color: #d22b28;
            border-color: #d22b28;
        }
    
        &:focus, &.focus, &:active{
            background-color: #d22b28 !important;
            border-color: #d22b28 !important;
            box-shadow: 0px 3px 5px -1px rgba(0,0,0,0.2), 0px 6px 10px 0px rgba(0,0,0,0.14), 0px 1px 18px 0px rgba(0,0,0,0.12) !important;
        }
    
        i{
            font-size: 1.75rem;
        }
    }
    
    .btn{
        &:focus{
            box-shadow: none;
        }
    }
    .hide{
    
    visibility: hidden
    
    }
}
/*dropdown css START*/
.welcomeoc-program{
    .uw-dropdown{
        &.whoc-dropdown{
            .dropdown-menu{
                padding: 0px 1.25rem;
                box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.43);
                border: 0;
                .dropdown-item{
                    color: $red;
                    font-size: 0.875rem;
                    border-bottom: 1px solid #f9d4d3;
                    padding: 0.5rem 0;
                    &:hover, &:active{
                        background-color: transparent;
                    }
                    &:focus{
                        outline: none;
                    }
                    &:last-child{
                        border-bottom: 0;
                    }
                    span{
                        width: 25px;
                        display: inline-block;
                    }
                    a{
                        color: $red;
                    }
                }
            }
        }
    }
    
    .MuiTooltip-tooltip{
        background-color: $black !important;
        font-size: 14px !important;
    }
    
    .MuiTooltip-arrow{
        color: $black !important;
    }
    
    .whoc-multi-fields{
        .custom-control-label{
            font-weight: initial;
            font-size: .8125rem;
        }
        .custom-control-label::after{
            width: .8125rem;
            height: .8125rem;
        }
        .custom-control-label::before{
            width: .8125rem;
            height: .8125rem;
        }
        label{
            font-weight: 500;
            font-size: .9375rem;
        }
        hr{
            margin-top: .5rem;
            margin-bottom: .5rem;
        }
        .alert-small-text{
            position: relative;
        }
        .paid-by{
            font-size: .8125rem;
        }
    }
    
    .wh-note{
        border-left: 3px solid #e0dfdf;
        font-size: .875rem;
        background-color: #72c8af;
        span{
            font-weight: bold;
            padding-left: .625rem;
        }
    }
    
    .wh-border{
        &:after{
            position: absolute;
            border:1px solid #fff;
            content: "";
        }
    }
    
    .table-cgreen{
        &.table{
            thead{
                th{
                    background-color: #d8fbf0;
                }
            }
            th, td{
                padding: .5rem 0.75rem;
                font-size: .875rem;
            }
            td{
                vertical-align: middle;
                i{
                    color: #50ad90;
                }
            }
        }
    }
}

/*****************************************
  upload button styles
******************************************/
.welcomeoc-program{
    .file-upload {
        position: relative;
        display: inline-block;
    }
    
    .file-upload__label {
      display: block;
      padding: .125rem .375rem;
      color: #fff;
      background: #50ad90;
      transition: background .3s;
      margin-bottom: 0;
      
      &:hover {
         cursor: pointer;
         background: #47a085;
      }
    }
        
    .file-upload__input {
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        width:0 !important;
        height: 100%;
        opacity: 0;
    }
    
    .doc-table{
        font-size: .875rem;
        thead{
            tr{
                background-color: #d6d8db;
            }
            th{
                border-bottom-width:0;
            }
        }
        th, td{
            padding: .375rem .5rem;
        }
        .asterisk {
            color: #d8000c;
            font-size: 8px;
            position: relative;
            right: 1px;
            top: -6px;
        }
    }
    
    .pha-note{
        @media (min-width: 600px){
            flex-grow: initial;
        }
            color: #fff;
            display: flex;
            padding: 6px 16px;
            flex-grow: 1;
            flex-wrap: wrap;
            font-size: 0.875rem;
            align-items: center;
            font-family: "Roboto", "Helvetica", "Arial", sans-serif;
            font-weight: 500;
            line-height: 1.43;
            border-radius: 4px;
            letter-spacing: 0.01071em;
        .pha-note-msg{
            padding: 8px 0;
        }
        .pha-note-action{
            display: flex;
            align-items: center;
            margin-left: auto;
            margin-right: -8px;
            padding-left: 16px;
        }
    }
    
    .wh-error{
        background-color: #f44336;
    }
    .wh-success{
        background-color: #4caf50;
    }
    .wh-inprogress{
        background-color: #ff9800;
    }
    
    .whoc-multi-fields {
        .pha-radio{
            .custom-control-label::before{
                left: -1.5625rem;
            }
            .custom-control-label::after{
                left: -1.625rem;
            }
        }
    }
    
    .asterisk{
        color: #D8000C;
        font-size: 8px;
        position: absolute;
        right: -9px;
        top: -1px;
      }
    
    .yellow-loader{
        .MuiCircularProgress-colorPrimary {
            color: $yellow !important;
        }
    }
    
    button{
        cursor: pointer;
    }
    
    .MuiSnackbar-root {
        z-index: 1202 !important;
    }

    .legend{
        position: absolute;
        bottom: 24px;
        left: 13px;
    }
    .legend-square{
        width: 12px;
        height: 12px;
        background: #C0DCF5;
        display: inline-block;
        vertical-align: middle;
        border-radius: 2px;
    }
    .legend-text{
        font-size: 12px;
        font-weight: 500;
        padding-left: 8px;
        color: rgba(0, 0, 0, 0.87);
        display: inline-block;
        vertical-align: middle;
    }
    
    .notification-text{
        color:#e13e3b;
        text-decoration: underline;
    }
}

