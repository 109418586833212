body {
    margin: 0;
    /* font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Montserrat",
        "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
        "Helvetica Neue", sans-serif; */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    /* font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
        monospace; */
}

body {
    background-color: #f4f4f4;
}

.welcome {
    padding-top: 20px;
    text-align: center;
    font-size: 40px;
    padding-bottom: 15px;
    color: white;
    font-family: Alegreya Sans;
}
.login-block {
    text-align: center;
    background-color: #1864af;
    width: 100vw;
    margin: 0px;
}

.register-user-button {
    text-align: center;
    height: 70px;
    width: 35%;
    widows: 75px;
    cursor: pointer;
    border-radius: 5px;
    background-color: #ff9500;
    color: white;
    font-weight: bold;
    font-size: 20px;
    margin: 20px;
    margin-right: 5px;
    border: hidden;
}

.submit-claim-button {
    text-align: center;
    height: 70px;
    width: 35%;
    widows: 75px;
    cursor: pointer;
    border-radius: 5px;
    background-color: #ff9500;
    color: white;
    font-weight: bold;
    font-size: 20px;
    margin: 20px;
    margin-left: 5px;
    border: hidden;
}

.signout-button {
    position: absolute;
    right: 5px;
    top: 5px;
    height: 7vh;
    width: 100px;
    widows: 75px;
    cursor: pointer;
    background-color: #ff9500;
    color: white;
    font-weight: bold;
    font-size: 15px;
    border: hidden;
    border-radius: 5px;
}

.reset-password {
    font-size: small;
    color: white;
}

.input-field {
    position: relative;
    width: 20%;
    height: 30px;
    margin: 5px;
    border-radius: 5px;
    font-size: 15px;
    border: hidden;
}

.input-field-2 {
    position: relative;
    width: 340px;
    height: 30px;
    margin: 5px;
    border-radius: 5px;
    font-size: 15px;
    border: hidden;
}

.name {
    position: relative;
    width: 160px;
    height: 30px;
    margin: 5px;
    border-radius: 5px;
    font-size: 15px;
    border: hidden;
}

.email {
    position: relative;
    width: 250px;
    height: 30px;
    margin: 5px;
    border-radius: 5px;
    font-size: 15px;
    border: hidden;
}

.input-label {
    text-align: center;
    color: white;
    padding-right: 5px;
    margin-left: auto;
    margin-right: auto;
    display: inline;
}

.input-label-3 {
    padding-top: 10px;
    text-align: center;
    color: white;
    padding-right: 5px;
    margin-left: auto;
    margin-right: auto;
    display: block;
}

.workstatus {
    margin-top: 5px;
    padding-top: 0px;
    padding: 5px;
    width: 340px;
}

.login-button {
    height: 35px;
    width: 20%;
    widows: 75px;
    cursor: pointer;
    border-radius: 5px;
    background-color: #ff9500;
    color: white;
    font-weight: bold;
    font-size: 15px;
    border: hidden;
}

.bottom-login {
    padding-bottom: 20px;
}

.banner {
    display: flex;
    text-align: left;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 0px;
    padding: 0px;
    background-color: #f4f4f4;
    height: 7vh;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 5px;
}

.banner-img {
    position: relative;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    width: 300px;
    border-radius: 10px;
}

.banner-text {
    color: #005191;
    font-size: 50px;
    font-weight: bold;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
}

.register-button {
    cursor: pointer;
    font-weight: bold;
    border-radius: 5px;
}

.reset-password {
    cursor: pointer;
    font-weight: bold;
    display: inline-flex;
    margin-top: 5px;
    margin-bottom: 10px;
    margin-left: 5px;
    font-size: small;
}

.section-1 {
    height: 30vh;
}

.content {
    text-align: center;
    background-color: #f4f4f4;
    margin-left: auto;
    margin-right: auto;
}

.SessionInfo {
    text-align: center;
    background-color: white;
    width: 80%;
    height: 300px;
    margin-left: auto;
    margin-right: auto;
    overflow: scroll;
}

.heart-img {
    position: relative;
    margin-bottom: 10px;
    width: 840px;
    height: 400px;
}

.other-input {
    border: hidden;
    height: 20px;
    width: 330px;
    padding: 2px;
    border-radius: 5px;
    margin-left: auto;
    margin-right: auto;
}

.add-user-form {
    /* text-align: center;
  background-color: #1864af;
  width: 100vw;
  margin: 0px;
  height: 85vh;
  overflow-y: scroll;
  padding-bottom: 100px; */
}

.panel-title {
    text-align: center;
    color: white;
    font-size: 30px;
    padding-top: 30px;
}
.panel-title-2 {
    text-align: center;
    color: white;
    font-size: 30px;
    margin-right: 80px;
    padding-top: 8px;
}

.submit-claim-form {
    text-align: center;
    background-color: rgb(39, 39, 39);
    width: 655px;
    height: 50vh;
    padding-top: 0px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 0px;
    border-radius: 10px;
}

.close-panel {
    position: relative;
    height: 30px;
    font-weight: bold;
    font-size: large;
    color: white;
    background-color: #ff9500;
    border: hidden;
    cursor: pointer;
    float: left;
}

.close-panel2 {
    position: relative;
    width: 50px;
    height: 30px;
    font-weight: bold;
    font-size: large;
    color: white;
    background-color: #ff9500;
    border: hidden;
    cursor: pointer;
    float: right;
}

.save-notes-button {
    position: relative;
    width: 60px;
    height: 30px;
    font-weight: bold;
    font-size: large;
    color: white;
    background-color: #ff9500;
    border: hidden;
    cursor: pointer;
    float: right;
    margin-right: 10px;
}

.add-user-button {
    height: 30px;
    width: 150px;
    font-weight: bold;
    font-size: large;
    border-radius: 5px;
    color: white;
    background-color: #ff9500;
    border: hidden;
    cursor: pointer;
    margin-top: 15px;
    margin-right: 5px;
}

.delete-user-button {
    height: 30px;
    width: 150px;
    font-weight: bold;
    font-size: large;
    border-radius: 5px;
    color: white;
    background-color: #ff3c00;
    border: hidden;
    cursor: pointer;
    margin-top: 5px;
    margin-right: 5px;
}

.load-user-button {
    height: 30px;
    width: 80px;
    font-weight: bold;
    font-size: large;
    border-radius: 5px;
    color: white;
    background-color: #ff9500;
    border: hidden;
    cursor: pointer;
    margin-right: 5px;
}

.submit-claim-button2 {
    height: 30px;
    width: 100px;
    font-weight: bold;
    font-size: large;
    border-radius: 5px;
    color: white;
    background-color: #ff9500;
    border: hidden;
    cursor: pointer;
}

.message {
    text-align: center;
    font-family: Teko;
    font-size: 45px;
    padding-top: 30px;
    padding-bottom: 30px;
    font-weight: bold;
    color: #151984;
}

.message2 {
    font-family: Teko;
    text-align: center;
    font-style: italic;
    font-size: 30px;
    padding-top: 5px;
    padding-bottom: 20px;
    font-weight: bold;
    color: #151984;
}

.alert-msg {
    margin: 10px;
    color: rgb(253, 253, 253);
    font-size: 20px;
}

.file-input {
    color: white;
    margin: 10px;
}

.search-claims-form {
    background-color: #005191;
    /* height: 80vw;
  overflow-y: scroll; */
}
.top-section {
    background-color: #005191;
    height: 60px;
}

.search-title-message {
    background-color: #005191;
    height: 30px;
    color: white;
    font-size: 30px;
    padding-bottom: 5px;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    padding-bottom: 10px;
}

.report-selectors {
    width: 100%;
    height: 25px;
    background-color: #005191;
    padding-top: 0px;
    margin-top: 10px;
    display: inline-flex;
}

.report-button {
    color: #005191;
    padding-right: 10px;
    padding-left: 10px;
    background-color: #dadada;
    border: 1px solid rgb(235, 235, 235);
    font-weight: bold;
    cursor: pointer;
    border-bottom: 1px solid #005191;
    border-right: 1px solid #005191;
}

.data-table {
    background-color: #f4f4f4;
    /* overflow-y: scroll; */
    position: relative;
    z-index: 0;
}

.process-section {
    width: 100%;
    background-color: #f4f4f4;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
}

.files-section {
    display: inline-flex;
    width: 100%;
    text-align: center;
    background-color: #005191;
    padding: 5px;
    box-sizing: border-box;
}

.approve-section {
    text-align: center;
    padding-bottom: 40px;
}

.close-panel {
    margin-left: 5px;
    border: hidden;
    height: 30px;
    border-radius: 5px;
    margin-top: 15px;
}

.load-claim-button {
    display: inline-flex;
    width: auto;
    margin-right: auto;
    margin-left: 5px;
    font-weight: bold;
    font-size: large;
    border-radius: 5px;
    padding: 5px;
    color: white;
    background-color: #ff9500;
    border: hidden;
    cursor: pointer;
}

.download-file-button {
    height: 30px;
    width: 200px;
    font-size: large;
    border-radius: 5px;
    color: #005191;
    border: hidden;
    cursor: pointer;
    text-align: right;
}

.view-file-button {
    height: 30px;
    width: 100px;
    font-weight: bold;
    font-size: large;
    border-radius: 5px;
    margin-left: 5px;
    color: white;
    background-color: #ff9500;
    border: hidden;
    cursor: pointer;
}

.result {
    width: 200px;
    text-align: center;
    font-size: 18px;
    margin-top: 0px;
    height: 30px;
}

.file-row {
    display: inline-flex;
    margin: 5px;
}

.download-files-text {
    padding-top: 10px;
    padding-left: 50px;
    color: #005191;
    font-size: 20px;
    font-weight: bold;
    text-decoration: underline;
}

.submit-button-process {
    height: 30px;
    width: 100px;
    font-weight: bold;
    font-size: large;
    border-radius: 5px;
    color: white;
    background-color: #ff9500;
    border: hidden;
    margin-left: 10px;
    cursor: pointer;
}

.email-id-input {
    margin-left: auto;
    border: hidden;
    border-radius: 5px;
    width: 200px;
}

.result-text {
    color: #005191;
    font-size: 15px;
    font-weight: bold;
    text-decoration: underline;
}

.state {
    width: 50px;
    height: 30px;
}

.city {
    position: relative;
    width: 200px;
    height: 20px;
    margin: 5px;
    border-radius: 5px;
    font-size: 15px;
    border: hidden;
}

.zip {
    position: relative;
    width: 80px;
    height: 20px;
    margin: 5px;
    border-radius: 5px;
    font-size: 15px;
    border: hidden;
}

.alert-msg-2 {
    position: absolute;
    top: 30vh;
    height: 165px;
    text-align: center;
    left: 35vw;
    width: 300px;
    background-color: #005191;
    color: white;
    z-index: 5;
}
.alert-msg-3 {
    position: absolute;
    top: 30vh;
    height: 150px;
    text-align: center;
    left: 35vw;
    width: 300px;
    background-color: white;
    color: black;
    border: 3px solid black;
    border-radius: 5px;
}

.alert-msg-4 {
    position: absolute;
    top: 30vh;
    height: 150px;
    text-align: center;
    left: 35vw;
    width: 350px;
    background-color: white;
    color: black;
    border: 3px solid black;
    border-radius: 5px;
}

.notes-panel {
    position: absolute;
    top: 30vh;
    height: 420px;
    text-align: center;
    left: 25vw;
    width: 500px;
    background-color: #005191;
    color: white;
}

.notes-textarea {
    padding-top: 20px;
}

.notes-textbox {
    height: 350px;
    width: 400px;
    resize: none;
}

.alert-text {
    color: rgb(255, 255, 255);
    padding-top: 50px;
    font-size: large;
}

.alert-text-2 {
    color: black;
    padding-top: 50px;
    font-size: large;
}

.alert-text-3 {
    color: black;
    padding-top: 50px;
    padding-bottom: 20px;
    font-size: large;
}

.show-notes-button {
    height: 30px;
    width: 80px;
    font-weight: bold;
    font-size: large;
    border-radius: 5px;
    color: white;
    background-color: #ff9500;
    border: hidden;
    margin-right: 10px;
    cursor: pointer;
}

.view-file-button a {
    text-decoration: none;
    color: white;
}

.selector-line {
    display: block;
}

.add-table {
    margin-left: auto;
    margin-right: auto;
}

.DOB {
    position: relative;
    height: 28px;
    margin: 5px;
    margin-left: 20px;
    width: 160px;
    border-radius: 5px;
    font-size: 15px;
    border: hidden;
}

.DOB-label {
    text-align: center;
    color: white;
    padding-right: 0px;
    margin-left: 60px;
    display: inline;
}

.income-label {
    text-align: center;
    color: white;
    margin-left: auto;
    display: inline;
}

.number-input {
    width: 30px;
    margin-right: 5px;
    margin-top: 5px;
}

.input-field-work {
    position: relative;
    width: 245px;
    height: 20px;
    margin: 5px;
    border-radius: 5px;
    font-size: 15px;
    border: hidden;
}

.household-label-1 {
    text-align: center;
    color: white;
    padding-right: 10px;
    margin-left: auto;
    margin-right: auto;
    display: inline;
}

.household-label-2 {
    text-align: center;
    color: white;
    padding-right: 22px;
    margin-left: auto;
    margin-right: auto;
    display: inline;
}

.household-label-3 {
    text-align: center;
    color: white;
    padding-right: 15px;
    margin-left: 2px;
    margin-right: 2px;
    display: inline;
}

.household-label-4 {
    text-align: center;
    color: white;
    padding-right: 5px;
    margin-left: auto;
    margin-right: auto;
    display: inline;
}

.household-label-5 {
    text-align: center;
    color: white;
    padding-right: 20px;
    margin-left: auto;
    margin-right: 3px;
    display: inline;
}

.monthlyIncome {
    position: relative;
    width: 80px;
    height: 20px;
    margin: 5px;
    border-radius: 5px;
    font-size: 15px;
    border: hidden;
}

.card-input {
    height: 20px;
    margin-top: 2px;
    margin-left: 10px;
    margin-bottom: 0px;
    border: 1px solid black;
    border-radius: 5px;
    width: 80px;
}

.col1 {
    text-align: right;
}

@media only screen and (max-width: 600px) {
    .workstatus {
        margin-top: 5px;
        padding-top: 0px;
        padding: 5px;
        width: 300px;
    }
    .card-input {
        height: 20px;
        margin-top: 2px;
        margin-bottom: 5px;
        border: 1px solid black;
        border-radius: 5px;
        width: 80px;
    }
    .input-field-work {
        position: relative;
        width: 220px;
        height: 20px;
        margin: 5px;
        border-radius: 5px;
        font-size: 15px;
        border: hidden;
    }
    .monthlyIncome {
        position: relative;
        width: 40px;
        height: 20px;

        border-radius: 5px;
        font-size: 15px;
        border: hidden;
    }

    .income-label {
        text-align: center;
        color: white;
        margin-left: auto;
        padding: 10px;
        padding-bottom: 0px;
        padding-right: 0px;
        height: 30px;
    }

    .load-user-button {
        height: 25px;
        width: 80px;
        font-weight: bold;
        font-size: large;
        border-radius: 5px;
        color: white;
        background-color: #ff9500;
        border: hidden;
        cursor: pointer;
        margin-right: 5px;
    }
    .other-input {
        border: hidden;
        height: 20px;
        width: 280px;
        padding: 2px;
        border-radius: 5px;
        margin-left: auto;
        margin-right: auto;
    }

    .DOB-line {
        display: inline-flex;
    }
    .DOB {
        position: relative;
        height: 28px;
        margin: 5px;
        margin-left: 20px;
        width: 120px;
        border-radius: 5px;
        font-size: 15px;
        border: hidden;
    }

    .DOB-label {
        text-align: center;
        color: white;
        padding-right: 0px;
        margin-left: 60px;
        display: inline;
        padding: 5px;
    }
    .name {
        position: relative;
        width: 135px;
        height: 15px;
        margin: 5px;
        border-radius: 5px;
        font-size: 15px;
        border: hidden;
    }

    .email {
        position: relative;
        width: 210px;
        height: 15px;
        margin: 5px;
        border-radius: 5px;
        font-size: 15px;
        border: hidden;
    }

    .alert-msg-2 {
        position: absolute;
        top: 30vh;
        left: 20vw;
        width: 200px;
        background-color: #005191;
        color: white;
    }

    .alert-msg-3 {
        position: absolute;
        top: 30vh;
        left: 20vw;
        width: 200px;
        background-color: white;
        color: black;
        border: 3px solid black;
        border-radius: 5px;
    }

    .notes-panel {
        position: absolute;
        width: 300px;
        top: 30vh;
        left: 10vw;
    }

    .notes-textarea {
        padding-top: 35px;
    }
    .notes-textbox {
        height: 350px;
        width: 250px;
        resize: none;
    }
    .search-claims-form {
        background-color: #005191;
        height: 90%;
        padding-bottom: 130px;
    }
    .city {
        height: 20px;
        width: 170px;
    }

    .zip {
        width: 50px;
    }

    .result-text {
        color: #005191;
        font-size: 15px;
        font-weight: bold;
        text-decoration: underline;
        margin-top: 0px;
        padding-top: 0px;
    }

    .close-panel2 {
        width: 50px;
        padding-left: 7px;
        padding-right: 7px;
    }

    .submit-button-process {
        height: 30px;
        width: 100px;
        font-weight: bold;
        font-size: medium;
        border-radius: 5px;
        color: white;
        background-color: #ff9500;
        border: hidden;
        margin-left: 10px;
        cursor: pointer;
    }
    .result {
        width: 150px;
        text-align: center;
        font-size: 15px;
    }

    .download-files-text {
        padding-top: 15px;
        color: #005191;
        font-size: 15px;
        font-weight: bold;
        text-decoration: underline;
    }

    .report-button {
        font-size: 15px;
        padding-right: 7px;
        padding-left: 7px;
        color: #005191;
        padding-right: 10px;
        padding-left: 10px;
    }
    .data-table {
        margin-top: 0px;
        /* overflow-y: scroll; */
    }

    .search-title-message {
        background-color: #005191;
        height: 30px;
        color: white;
        font-size: 30px;
        padding: 5px;
        text-align: center;
    }

    .login-block {
        text-align: center;
        background-color: #1864af;
        width: 100vw;
        margin: 0px;
    }

    .signout-button {
        position: absolute;
        right: 5px;
        top: 5px;
        height: 7vh;
        width: 65px;
        widows: 75px;
        cursor: pointer;
        background-color: #ff9500;
        color: white;
        font-weight: bold;
        font-size: 15px;
        border: hidden;
        border-radius: 5px;
    }

    .heart-img {
        width: 350px;
        height: 200px;
    }
    .add-user-form {
        /* text-align: center;
        background-color: #1864af;
        width: 100vw;
        margin: 0px;
        height: 75vh;
        overflow-y: scroll;
        padding-bottom: 150px; */
    }

    .add-user-button {
        height: 30px;
        width: 150px;
        font-weight: bold;
        font-size: large;
        border-radius: 5px;
        color: white;
        background-color: #ff9500;
        border: hidden;
        cursor: pointer;
        margin-top: 10px;
    }

    .input-field {
        position: relative;
        width: 50%;
        height: 30px;
        margin: 5px;
        border-radius: 5px;
        font-size: 15px;
        border: hidden;
    }

    .login-button {
        height: 35px;
        width: 50%;
        widows: 75px;
        cursor: pointer;
        border-radius: 5px;
        background-color: #ff9500;
        color: white;
        font-weight: bold;
        font-size: 15px;
        border: hidden;
    }

    .input-field-2 {
        position: relative;
        width: 300px;
        height: 15px;
        margin: 5px;
        border-radius: 5px;
        font-size: 15px;
        border: hidden;
    }

    .submit-claim-form {
        text-align: center;
        background-color: rgb(48, 47, 47);
        margin-right: auto;
        margin-left: auto;
        margin-top: 0px;
        width: 95vw;
        height: 50vh;
        border-radius: 10px;
    }

    .banner-img {
        position: relative;
        text-align: center;
        margin-left: auto;
        margin-right: auto;
        width: 200px;
        border-radius: 10px;
    }
    .banner-text {
        text-align: center;
        color: #005191;
        font-size: 35px;
        width: 200px;
        font-weight: bold;
        margin-top: auto;
        margin-bottom: auto;
    }

    .message {
        text-align: center;
        font-size: 20px;
        padding-top: 30px;
        padding-bottom: 30px;
        padding-left: 0px;
        padding-right: 0px;
        font-weight: bold;
        color: #151984;
    }

    .message2 {
        text-align: center;
        font-style: italic;
        font-size: 15px;
        padding-top: 20px;
        padding-bottom: 20px;
        font-weight: bold;
        color: #151984;
    }

    .download-file-button {
        height: 30px;
        width: 200px;
        font-size: medium;
        border-radius: 5px;
        color: #005191;
        border: hidden;
        cursor: pointer;
        text-align: right;
    }

    .view-file-button {
        height: 20px;
        width: 80px;
        font-weight: bold;
        font-size: medium;
        border-radius: 5px;
        margin-left: 5px;
        color: white;
        background-color: #ff9500;
        border: hidden;
        cursor: pointer;
    }

    .file-row {
        display: inline-flex;
        margin: 0px;
    }

    .panel-title {
        text-align: center;
        color: white;
        font-size: 30px;
        padding-top: 30px;
    }
}

.mui-table-border th,
td {
  border: 1px solid rgba(224, 224, 224, 1);
}

.toolbar-text {
    margin: 5px;
    color: rgba(0, 0, 0, 0.87);
    font-size: 16px;
    padding: 2px;
}
