
.profile-title{
    font-size: 1.2rem;
    color: $primary;
    text-transform: uppercase;
    font-weight: bold;
   // font-family: "Montserrat", sans-serif;
}

/*record detial page css START*/
.record-detail-main{
    .box-gray{
        background: #f1f0f6 !important;
        border-bottom: 4px solid $secondary !important;
    }
    .profile-title{
        font-size: 1.2rem;
        background: #fff !important;
        padding-bottom: .3125rem;
    }
    .box-details{
        padding:0rem .9375rem;
        label{
            font-size: .8125rem;
            margin-bottom: 0;
        }
        .form-control{
            height: 1.875rem;
            font-size: .8125rem;
        }
    }
    .img-box{
        justify-content: center;
        align-items: center;
        display: flex;
        position: relative;
        transition: all 0.5s ease-out;
        &:hover{
            .custom-file-upload{
                display: block;
            }
        }
    }
}

.validation-text{
    .alert-small-text{
        position: absolute;
    }
}


.alert-small-text{
    color: $red;
    font-size: .75rem;
    background-color: transparent;
    border: 0;
    padding: 0;
    font-weight: bold;
    position: relative;
}

/*record detial page css END*/


.MuiTooltip-tooltip{
    background-color: $black !important;
    font-size: 12px !important;
}

.MuiTooltip-arrow{
    color: $black !important;
}

// .details-page{
//     margin-top: 5rem;
// }

.field-hightlight{
    .form-control{
        border:1px solid #4caf50;
    }
}

// floating btn
.assistoc-program {
.whoc-float-btn{
    width: 50px;
    height: 50px;
    border-radius: 100% !important;
    color: #fff;
    line-height: normal !important;
    padding: .3125rem !important;
    box-shadow: 0px 3px 5px -1px rgba(0,0,0,0.2), 0px 6px 10px 0px rgba(0,0,0,0.14), 0px 1px 18px 0px rgba(0,0,0,0.12) !important;
    min-width: 50px !important;

    &.btn-primary{
        color: #fff !important;
        background-color: #005191 !important;
        border-color: #005191 !important;
        &:hover{
            background-color: #064b82 !important;
            border-color: #064b82 !important;
            color: $white !important;
        }
        &:focus, &.focus, &:active{
            background-color: #064b82 !important;
            border-color: #064b82 !important;
            box-shadow: 0px 3px 5px -1px rgba(0,0,0,0.2), 0px 6px 10px 0px rgba(0,0,0,0.14), 0px 1px 18px 0px rgba(0,0,0,0.12) !important;
            color: $white !important;
        }
    }

    &.btn-secondary {
        color: #fff !important;
        background-color: #ffb351 !important;
        border-color: #ffb351 !important;
        &:hover{
            background-color: #ec9f3c !important;
            border-color: #ec9f3c !important;
            color: $white !important;
        }
        &:focus, &.focus, &:active{
            background-color: #ec9f3c !important;
            border-color: #ec9f3c !important;
            box-shadow: 0px 3px 5px -1px rgba(0,0,0,0.2), 0px 6px 10px 0px rgba(0,0,0,0.14), 0px 1px 18px 0px rgba(0,0,0,0.12) !important;
            color: $white;
        }
    }

    i{
        font-size: 1.75rem;
    }
}
}

.wh-note{
    border-left: 3px solid #e0dfdf;
    font-size: .875rem;
    span{
        font-weight: bold;
        padding-left: .625rem;
    }
}

.top-note {
    background: #005191;
    padding: 5px 2px;
    color: #fff;
    font-size: 16px;
}

.icon-disabled{
    color: #cccccc !important;
}

.property-grid{
    table{
        &.table-scroll{
            thead {
                 width: calc( 100% - 1em );
            }
        }
        thead{
            display: table;
            width: 100%;
            table-layout: fixed;
        }
        thead {
            th{
                // font-weight: 400;
            }
        }
        tbody{
            display: block;
            max-height: 180px;
            overflow: auto;
            @include mq("tablet-small", max) {
               overflow-y: auto;
               overflow-x: hidden;
            }
            tr{
                display: table;
                width: 100%;
                table-layout: fixed;
            }
        }
        th, td{
             border-top: 0;
             @include mq("tablet-small", max) {
                width:150px
            }
            &:last-child{
                @include mq("tablet-wide", max) {
                    width:50px;
                }
            }
        }
        th{
            border-top:0;
            font-size: 13px;
            padding-bottom: 5px;
            border-bottom: 1px solid #dee2e6;
        }
        td{
            margin-bottom: .3125rem;
            border-bottom: 1px solid #dee2e6;
            border-left: 0;
            border-right: 0;
            font-size: 14px;
        }
    }
}

.Mui-filledSuccess {
    .MuiSnackbarContent-root{
      color: #fff;
      font-weight: 500;
      background-color: #4caf50;
    }
  }
  .Mui-filledWarning {
  .MuiSnackbarContent-root{
    color: #fff;
    font-weight: 500;
    background-color: #ff9800;
  }
  }
  .Mui-filledError {
  .MuiSnackbarContent-root{
    color: #fff;
    font-weight: 500;
    background-color: #f44336;
  }
  }

  .details-page {
    margin-top: 1rem;
}

.assistoc-note{
    p{
        font-size: 14px;
        color: red;
        span{
            font-weight: bold;
        }
    }
}