body, .sticky-footer-wrapper {
   min-height:100vh;
}

.flex-fill {
   flex:1 1 auto;
}

.search-claims-form {
    background: $white;
    // margin-top: 5rem;

    .MuiPaper-rounded {
        border-radius: 0;
    }
    .MuiPaper-elevation4 {
        box-shadow: none;
    }
    .MuiToolbar-root {
        position: relative;
        background: #eaf1f6;
        margin-bottom: 4px;
        border-bottom: 1px solid #cbddec;
        &:after {
            content: "";
            position: absolute;
            height: 5px;
            left: 0;
            right: 0;
            bottom: -6px;
            background: $white;
            z-index: 1;
        }

        @include mq(md-phone, max) {
            padding: 0 0 8px 0;
        }
    }

    .MuiIconButton-root {
        background: $white;
        color: $primary;
        margin: 0 5px;
        padding: 8px;
        @include mq(tablet, max) {
            margin: 0 2px;
        }
        @include mq(md-phone, max) {
            margin: 0 10px;
        }
        &:focus {
            outline: none;
        }
        &:hover {
            background-color: $primary;
            color: $white;
        }
    }

    .MuiInputBase-input {
        background: #fff;
        padding: 0 12px;
        border-radius: 50px;
        border: 0;
        height: 38px;

        @include mq(md-phone, max) {
            height: 32px;
        }
    }

    .MuiInput-root {
        &:after,
        &:before {
            border-bottom: none !important;
        }
    }

    .MuiToolbar-gutters {
        padding-left: 10px;
        padding-right: 10px;
    }

    .MUIDataTablePagination-root-404 {
        &:last-child {
            padding: 0;
        }
    }

    table {
        &.MuiTable-root {
            tbody{
                
            border: 1px solid #ebebea;
            }
        }
        thead {
            &.MuiTableHead-root {
                tr {
                    th {
                        background: #dbe7f1;
                        padding: 0 12px !important;
                    }
                }
            }
        }
        tfoot {
            .MuiToolbar-root {
                background: #fff;
                margin-bottom: 0;
                border-bottom: 1px solid #fff;
                &:after {
                    display: none;
                }
            }
            .MuiInputBase-input {
                height: auto;
            }
            [class*="MUIDataTablePagination-root"] {
                padding: 0 !important;
                border:0;
            }
        }
    }

    @include mq(md-phone, max) {
        [class*="MUIDataTableSearch-main-"] {
            justify-content: center;
            margin-bottom: 8px;
        }
        .MuiToolbar-root {
            > [class*="jss"] {
                margin-bottom: 8px;
                > div{
                    justify-content: center;
                }
            }
        }
    }
}

.border-1 {
    border: 1px solid #ebebea;
}


.modal-backdrop-static {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1040;
    width: 100vw;
    height: 100vh;
    background-color: #000;
    opacity:0.5;
}

.MuiTableCell-root {
    padding: 6px 12px !important;
    i{
        &.fas{
            font-size: 1rem;
        }
    }
}

[class*="MUIDataTableFilterList-chip-"] {
    margin: 0 4px 4px !important;
}

[class*="MUIDataTableFilterList-root-"] {
    margin: 0 !important;
}

.Mui-checked{
    .MuiIconButton-label{
        .MuiSvgIcon-root{
            fill: $primary;
        }
    }
}


[class*="MUIDataTableHeadCell-sortAction-"] {
    cursor: pointer;
    display: flex;
    vertical-align: top;
    align-items: center;
    .MuiSvgIcon-root{
        fill: $black;
    }
}

@media (min-width: 600px){
    .MuiToolbar-regular{
        min-height: 50px !important;
    }
}

i{
    &.big{
        font-size:120%;
    }
}

.op-1{
    opacity:1 !important;
    &:hover{
        opacity:1 !important;
    }
}

.icon-title{
    max-width: 28px;
    @include mq(md-phone, max) {
        max-width: 20px;
    }
}

.search-claims-form table tfoot .MuiToolbar-root {
    border-bottom: 0 !important;
}

.MuiTablePagination-root {
    border: 0 !important;
}

.MuiChip-root {
    margin: 0 0 8px !important;
}

.MuiTablePagination-root {
    border: 0 !important;
}

.pending-status{
    background-color: #ffd59e;
    &:hover{
        background-color: #ffd59e !important;
    }
}

.MuiPaper-elevation1{
    border-radius: 100px;
    box-shadow: none;
    .MuiTab-textColorPrimary.Mui-selected {
        background: #005191;
        border-radius: 100px;
        color: #fff;
    }
    .MuiTabs-indicator{
        display: none;
    }
    .MuiTabs-root{
        min-height: 35px;
    }
    .MuiTab-root{
        min-width: 100px;
        min-height: 35px;
        font-size: 13px;
    }
    button:focus{
        outline: 0;
    }
    .MuiTouchRipple-root{
        border-radius: 100px;
    }
}

.not-allowed {
    font-size: 18px;
    color: red;
}

@media only screen and (max-width: 900px) {
    .cepp-grid{
        &.search-claims-form{
            margin-top: 8rem;
        }
        [class*="MUIDataTableToolbar-actions-"]{
            display: inherit;
        }
        h4{
            margin-bottom: 3.75rem;
        }
        .MuiPaper-elevation1{
            position: absolute;
            top: -41px;
            left: 0;
            width: 100%;
        }
        .MuiTabs-root{
            min-height: 25px;
        }
        .MuiTab-root{
            min-width: 33.33%;
            min-height: 25px;
            font-size: 11px;
        }
    }
}

@media only screen and (max-width: 576px) {
    .cepp-grid{
        &.search-claims-form{
            margin-top: 2rem;
        }
    }
}

.cepp-grid {
    .MuiPaper-elevation1{
        &.asoc-nested-table{
            @media only screen and (max-width: 900px) {
                position: relative;
                top: 0;
            }
        }
    }
}

.asoc-nested-table{
    padding: 15px;
    .MuiTableCell-body{
        border-bottom: 1px solid rgba(224, 224, 224, 1);
        border-right: 0;
        border-top: 0;
        border-left: 0;
        font-size: 13px;
    }
    table.MuiTable-root tbody{
        border:0;
    }
}

.report-btn{
    position: absolute;
    bottom: 1.125rem;
    left: .625rem;
    @media only screen and (max-width: 560px) {
        position: relative;
        bottom: 0;
        left: 0;
        margin:10px 0
    }
}

.report-transaction-btn{
    position: absolute;
    bottom: 1.125rem;
    left: 12.625rem;
    @media only screen and (max-width: 560px) {
        position: relative;
        bottom: 0;
        left: 0;
        margin:10px 0
    }
}

.recTable {
    min-width: 1600px;
}

.MuiPopover-root .MuiPopover-paper .MuiGridListTile-root {
    padding: 0 17px !important;
    }

.MuiPopover-root .MuiPopover-paper .MuiGridList-root {
    overflow-y:hidden !important;
    }