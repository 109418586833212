@charset "UTF-8";

// 1. Configuration and helpers
@import "scss/abstract/variable", "scss/abstract/variable-bootsrap",
    "scss/abstract/mixin";

// 2. Vendors
@import "../node_modules/bootstrap/scss/bootstrap.scss",
    "../node_modules/@fortawesome/fontawesome-free/scss/solid.scss",
    "../node_modules/@fortawesome/fontawesome-free/scss/fontawesome.scss";

// 3. Base stuff
@import "scss/base/base", "scss/base/font", "scss/base/type", "scss/base/helper";

// 4. Layout-related sections
@import "scss/layout/header", "scss/layout/footer";

// 5. Components
@import "scss/component/modal", "scss/component/mdinput", "scss/component/autocomplete";

// 6. Page-specific styles
@import "scss/page/login", "scss/page/dashboard", "scss/page/search-claim", "scss/page/record-details", "scss/page/welcome-oc", "scss/page/pha";
