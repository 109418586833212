// This file contains CSS helper classes.

// Clear inner floats
.clearfix::after {
    clear: both;
    content: "";
    display: table;
}

// box shadow
.box-shadow {
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.14) !important;
}

.card-shadow {
    box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.43);
}

.no-border-radius {
    border-radius: 0 !important;
}

// authentication message
.Toast__toast___XXr3v {
    color: $white !important;
    background: $danger !important;
    font-weight: 500;
}

.yellow-box{
    background: $yellow;
    padding: .625rem;
}

.btn-black{
    background-color: $black;
    border-color: $black;
    color: $white;
}

.page-title{
    font-size: 1.2rem;
}

.form-control-file{
    font-size: .875rem;
}

.right-mark{
    color: green;
}

.close-mark{
    color: $red;
}

.d-flow-root{
    display: flow-root;
}

.font-14{
    font-size: .875rem;
}