// This file contains very basic styles.

// Set up a decent box model on the root element
html {
  box-sizing: border-box;
  height: 100%;
}

body {
  height: 100%;
  background-size: contain;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

// Basic styles for links
a {
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}

// checkbox
.custom-control-label::before{
  border: $black solid 1px;
  top: 0.125rem;
}

.custom-control-label::after{
  top: 0.125rem;
}

.custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  border-color: $blue;
  background-color: $blue;
}

.custom-control-input:focus ~ .custom-control-label::before {
    box-shadow: none;
}

.custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #8fc2ef;
}

.custom-control-input:not(:disabled):active ~ .custom-control-label::before {
  color: #fff;
  background-color: #a9d6fd;
  border-color: #a9d6fd;
}

.custom-control-label{
  vertical-align: middle;
  font-size: 0.875rem;
}

label{
  font-size: 0.875rem;
  position: relative;
}

@for $i from 1 through 1000 {
  .width-#{$i} {
  width : #{$i}% !important;
  }
} 

.col-form-label{
  font-size: 0.875rem;
}

.form-control:focus {
  box-shadow: none;
}

textarea{
  resize: none;
}

.btn:focus, .btn.focus{
  box-shadow: none;
}

.btn{
  border-radius: .25rem;
  text-transform: uppercase;
  font-weight: 500;
}

// .btn{
//   color: #fff;
//   text-transform: uppercase;
//   border-radius: 0.25rem;
//   padding: 0.625rem 2.1875rem;
//   border:0;
// }

// .btn-light {
//   color: #212529;
//   background-color: transparent;
//   border-color: transparent;
// }

.btn-black{
  background-color: $black;
  border-color: $black;
}

.title-text{
  font-size: 1.875rem; //30px
}

.text-black{
  color: #000;
}

.subtitle-text{
  font-size: 1.25rem; //20px
}

p{
  font-size: 0.875rem;
  line-height: 1.5rem;
}

// .whoc-primary-btn{
//   background: $black;
//   border-radius: .25rem;
//   padding: .625rem 1.875rem;
//   border-color: $black;
//   text-transform: uppercase;
//   font-weight: 500;
// }

// .asterisk{
//   color: #D8000C;
//   font-size: 8px;
//   position: absolute;
//   right: -9px;
//   top: -1px;
// }

.required:after {
  content:"\f069";
  color: #D8000C;
  font-family: 'Font Awesome 5 Free';
  font-weight: 900;
  top: -7px;
  position: relative;
  right: 0;
  font-size: .5rem;
}

.text-blue{
  color: $blue;
}

.green{
  color: #47ba01;
}
.red{
  color: #d30000 !important;
}
.blue{
  color: #4679c5;
}
.pending-bg{
  background: #d4c403 !important;
}
.inactive-bg{
  background: #d30000 !important;
}
.leased-bg{
  background: #4679c5 !important;
}

.btn-primary:not(:disabled):not(.disabled):active:focus, 
.btn-primary:not(:disabled):not(.disabled).active:focus, 
.show > .btn-primary.dropdown-toggle:focus{
  box-shadow: none;
}

.MuiBackdrop-root {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  z-index: -1;
  position: fixed;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
  -webkit-tap-highlight-color: transparent;
  z-index: 1201 !important;
  .MuiCircularProgress-root{
    color: $yellow;
  }
}

.btn-black:hover, .btn-black:not(:disabled):not(.disabled):active,
.btn-black:focus, .btn-black.focus{
  background-color: #504d4d;
  border-color: #504d4d;
}

input{
  &:focus{
    outline: 0;
  }
}

/*****************************************
  upload button styles
******************************************/
.file-upload {
	position: relative;
	display: inline-block;
}

.file-upload__label {
  display: block;
  padding: .125rem .375rem;
  color: #fff;
  background: #50ad90;
  transition: background .3s;
  margin-bottom: 0;
  
  &:hover {
     cursor: pointer;
     background: #47a085;
  }
}
    
.file-upload__input {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    width:0 !important;
    height: 100%;
    opacity: 0;
}

.MuiTableSortLabel-root{
  height: auto !important;
}

.assistoc-speeddial{
  position: fixed;
  bottom: 25px;
  right: 20px;
  .MuiFab-primary{
    background-color: $primary;
    &:hover{
      background-color: #003c6b;
    }
    &:focus{
      outline: 0;
    }
  }
}
