// This file contains all fonts declarations

// Google Fonts
@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap);
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700;800;900&display=swap');


// Custom Fonts
@font-face {
  font-family: "league_gothicregular";
  src: url("../../fonts/leaguegothic-regular-webfont.woff2") format("woff2"),
    url("../../fonts/leaguegothic-regular-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
