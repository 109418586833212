.welcomeoc-program {
    .main {
    padding: 0 .9375rem; // 0 15px
    margin-top: 4rem;
    }
}

.assistoc-program {
    .main {
        padding: 0 2.6875rem;
        margin-top: 4rem;
    }
}

.dashboard{
    align-items: center;
    @include mq("desktop-wide", min) {
        display: flex;
    }
    main{
        margin-top: 0;
    }
}

.welcome-box {
    padding: 1rem 0; //90px 130px
    min-height: 600px;
    @include mq("desktop-wide", max) {
        padding: 1rem 0; //40px 80px
    }

    @include mq("desktop", max) {
        padding: 0.5rem 0; //32px
    }

    @include mq("phone", max) {
        padding: 0.5rem 0;
    }
    p,
    a {
        font-size: 1.125rem; //18px
    }

    .text-welcome {
        font-weight: 300;
        font-size: 1.5rem; //24px
        color: #005191;
        font-weight: bold;
    }

    .welcome-subtitle{
        color: #454545;
        font-size: 1rem;
    }

    .title {
        font-size: 1.875rem; //30px
    }

    .text-claim {
        font-weight: 500;
        font-size: 1.25rem; //20px
        display: inline-block;
    }

    hr {
        margin: 1rem 0;
        @include mq("desktop-wide", max) {
            margin: 1rem 0;
        }
    }

    .pb-row {
        display: flex;
        justify-content: space-between;

        @include mq("desktop", max) {
            display: block;
        }

        .pb-col {
            flex-basis: 48%;
            box-sizing: border-box;
            box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.21);
            background: #ffa128;
            transition: .5s ease-out;
            cursor: pointer;

            &:hover{
                background: #fb9a1e;
                box-shadow: 0px 0px 8.82px 0.18px rgba(0, 0, 0, 0.58);
            }

            @include mq("desktop", max) {
                margin-bottom: 0.9375rem;
            }

            &.bg-blue{
                background: #3d8cd2;

                &:hover{
                    background: #2f86d4;
                }
            }

            &.bg-green{
                background: #70c4aa;

                &:hover{
                    background: #5cb79a;
                }
            }
        }
    }

    .program-box{
          
        .program-logo{
            min-height: 5.3125rem;
            line-height: 85px;
            background: $white;
        }

        .program-content{
            color: $white;
            padding: 0.9375rem;
            
            h3{
                font-size: 1.25rem; //20px
                margin: 0;
            }

            p{
                font-size: 0.8125rem; //13px
                padding-top: 1.25rem;
                text-align: left;
            }
        }
    }
}

.hero-image {
    height: 100%;
    background-size: cover;
    background-position: center center;
}

.landing-btn{
    min-width: 300px;
    select{
        width: 300px;
        margin: 0 auto;
    }
    button{
        min-width: 300px;
    }
}

.ERA-title{
    font-size: 18px;
    vertical-align: middle;
    display: inline-block;
    color: #ffa128;
    font-weight: 700;
}