// This file contains Sass variables.

/// Regular font family

// Font Variables
$roboto: "Roboto", sans-serif;
$montserrat: 'Montserrat', sans-serif;
$league: "league_gothicregular", sans-serif;
$light-g: #cfd8df;
$yellow:#e0d446 !default;
$ligh-blue:#4994d6 !default;
$black: #020202 !default;
$red: #e13e3b !default;
$c-green: #70c4aa !default;
$blue: #5c9ad0 !default;
